<template>
  <v-row>
    <Confirmacion
      :pregunta="consultaDialog"
      :consulta="preguntaConsulta"
      :loading="loadingPregunta"
      @si="confirmacionAceptada"
      @no="consultaDialog=false"
      v-if="actions"
    />
    <v-col cols="12">
      <v-row>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        <NuevaCausa @nueva="agregarCausa" />
      </v-row>
       <small v-show="select">Causas Seleccionadas {{selectedIds.length}}</small>
      <v-data-table
        v-model="selected"
        :headers="computedHeaders"
        :items="desserts"
        item-key="id"
        :server-items-length="totalDesserts"
        :hide-default-footer="hiddenFoter"
        noDataText="No hay ninguna Experiencia Registrada para el Abogado"
        :mobile-breakpoint="100"
        calculate-widths
        :show-select="select"
      >
        <template v-slot:item.acciones="{item}">
          <v-icon
            v-if="item.verificacion==null"
            small
            color="error"
            class="mr-2"
            @click="eliminarExperiencia(item)"
          >mdi-trash-can</v-icon>
        </template>
        <template v-slot:item.verificacion="{ item }" class="hidden-xs-only">
          <v-chip
            :color="colorEstado(item.verificacion)"
            text-color="white"
          >{{textoEstado(item.verificacion)}}</v-chip>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import Mabogado from "@/mixins/abogado";
import NuevaCausa from "@/components/NuevaCausa";
import Confirmacion from "@/common/util/Confirmacion";
export default {
  props: {
    select: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Boolean,
      default: true
    }
  },
  components: { NuevaCausa, Confirmacion },
  mixins: [Mabogado],
  data() {
    return {
      consultaDialog: false,
      preguntaConsulta: "",
      tipoConsulta: null,
      loadingPregunta: false,
      causaSeleccionada: {},
      selected: [],
      headers: [
        {
          text: "Acciones",
          align: "left",
          value: "acciones",
          sortable: false,
          width: "70px"
        },
        {
          text: "N° NUREJ",
          align: "left",
          value: "numeroNurej",
          sortable: false,
          width: "150px"
        },
        {
          text: "Causa",
          align: "left",
          value: "tipoCausa",
          sortable: false,
          width: "200px"
        },
        {
          text: "Edad NNA",
          align: "left",
          value: "edadMenor",
          sortable: false,
          width: "200px"
        },
        {
          text: "Distrito",
          align: "left",
          value: "distrito",
          sortable: false,
          width: "200px"
        },
        {
          text: "Estado",
          align: "left",
          value: "verificacion",
          sortable: false,
          width: "200px"
        }
      ],
      desserts: [],
      hiddenFoter: true
    };
  },
  async created(){
    await this.getCausasAbogado();
  },
  computed: {
    totalDesserts() {
      if (this.desserts.length > 8) {
        this.hiddenFoter = false;
      }
      return this.desserts.length;
    },
    selectedIds() {
      var ids = [];
      if (this.selected.length > 0) {
        this.selected.forEach(item => {
          ids.push(item.id);
        });
      }
      this.$emit("datosSeleccionados", ids);
      return ids;
    },
    computedHeaders() {
      if (!this.actions) {
        let i = this.headers.map(item => item.text).indexOf("Acciones");
        this.headers.splice(i, 1);
      }
      return this.headers;
    }
  },
  methods: {
    colorEstado(data) {
      if (data === null) {
        return "primary";
      } else if (data) {
        return "success";
      }
      return "error";
    },
    textoEstado(data) {
      if (data === null) {
        return "NUEVO";
      } else if (data) {
        return "VERIFICADO";
      }
      return "RECHAZADO";
    },
    eliminarCausa(item) {
      Object.assign(this.causaSeleccionada, item);
      this.preguntaConsulta = `¿ Estas Seguro de Eliminar la Defensa de Causa N° <strong>" ${this.causaSeleccionada.numeroNurej} "</strong> ?.`;
      this.consultaDialog = true;
    },
    confirmacionAceptada() {
      this.loadingPregunta = true;
      this.deleteExperiencia(this.causaSeleccionada.id);
    },
    agregarCausa(data) {
      this.desserts.unshift(data);
      if (this.select) {
        this.selected.push(data);
      }
    }
  }
};
</script>
