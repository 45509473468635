<template>
  <v-col cols="12">
    <v-btn color="primary lighten-3" @click="dialog=true" class="mr-3">
      <v-icon>mdi-briefcase-plus</v-icon>DEF. CAUSA
    </v-btn>
    <v-dialog v-model="dialog" max-width="700px" transition="dialog-transition">
      <v-card>
        <v-toolbar color="primary">
          <span class="title white--text">{{titulo}}</span>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-form ref="formNuevaCausa" v-model="valid" lazy-validation>
            <Form :modelo="causa" :fields="fieldsCausa" :edit="edit" :create="true"/>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelarRegistro">cancelar</v-btn>
          <v-btn color="primary" @click="registrarCausa" :disabled="!valid" :loading="loading">GUARDAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import Form from "@/common/util/crud/Form";
export default {
  components: { Form },
  data() {
    return {
      titulo:'Registrar Nueva Causa',
      valid: true,
      edit: true,
      dialog: false,
      causa: {
        numeroNurej: null,
        tipoCausa: null,
        edadMenor: null,
        distrito: null
      },
      fieldsCausa: [],
      loading:false
    };
  },
  mounted() {
    this.fieldsCausa = [
      {
        label: "numero Nurej",
        model: "numeroNurej",
        rules:"requiredSelect",
        classField:"required px-1",
        type: "text",
        class: "lg4 md4"
      },
      {
        label: "Tipo Causa",
        model: "tipoCausa",
        type: "autocomplete",
        class: "lg4 md4",
        rules:"requiredSelect",
        classField:"required px-1",
        items: this.$store.state.session.parametros.causa
      },
      {
        label: "Edad Menor",
        model: "edadMenor",
        type: "text",
        class: "lg4 md4",
        rules:"required",
        classField:"required px-1"
      },
      {
        label: "Distrito",
        model: "distrito",
        type: "text",
        rules:"required",
        class: "lg6 md6",
        classField:"required px-1",
      },
    ];
  },
  methods:{
      registrarCausa(){
          if(this.$refs.formNuevaCausa.validate()){
              this.loading=true;
             this.$http.post(`${this.$apiUrl}abogado-ciudadano/causa`,{
               "causas":[this.causa]}).then(response=>{
               this.cancelarRegistro();
               this.$emit('nueva',response.data.data);
             }).catch(error=>{
                 this.loading=false;
             })
          }
      },
      cancelarRegistro(){
        this.loading=false;
        this.$refs.formNuevaCausa.reset();
        this.dialog=false; 
      }
  }
};
</script>

